export {ResourceType} from '@/enums/resourceType';
export {RecipientStatus} from '@/enums/recipientStatus';
export {QuestionType} from '@/enums/questionType';
export {SurveyType} from '@/enums/surveyType';
export {Route} from '@/enums/route';
export {Size} from '@/enums/size';

export enum RecipientType {
  Targeted = 'targeted',
  Open = 'open',
}
