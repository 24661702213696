export function isTargeted(surveyType: string): boolean {
  return surveyType.includes('targeted');
}
export function isIntegrated(surveyType: string): boolean {
  return (
    surveyType.includes('integrated') && !surveyType.includes('non-integrated')
  );
}
export function isOpen(surveyType: string): boolean {
  return surveyType.includes('open');
}
