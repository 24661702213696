import {Module} from 'vuex-class-modules';
import store from '@/store';
import {submitterMessageApi as api} from '@/api';
import {
  SubmitterMessageAttributes as Attributes,
  SubmitterMessageCreate as Create,
  SubmitterMessageResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {parseISO} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.SubmitterMessage,
    attributes: {
      id: 0,
      body: '',
      bucket: '',
      emailFieldKey: '',
      firstNameFieldKey: '',
      lastNameFieldKey: '',
      recipients: [],
      sentDate: new Date(),
      subject: '',
      surveyId: '',
      status: '',
      userId: '',
      user: {id: 0, firstName: '', lastName: ''},
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class SubmitterMessageModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {
  public cast(item: Resource): Resource {
    item.attributes.sentDate = parseISO(`${String(item.attributes.sentDate)}`);
    item.attributes.created = parseISO(`${String(item.attributes.created)}`);
    item.attributes.modified = parseISO(`${String(item.attributes.modified)}`);
    return item;
  }
}

export const submitterMessageModule = new SubmitterMessageModule(
  {
    store,
    name: ResourceType.SubmitterMessage,
  },
  () => api,
  defaultItem
);
