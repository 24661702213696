import Vue from 'vue';
import {format as formatDate, formatDistanceToNow} from 'date-fns';

Vue.filter('n2br', (value: string) => value.replace('\n', '<br/>'));
Vue.filter('dateFormatDistanceToNow', (value: string) =>
  formatDistanceToNow(new Date(value))
);
Vue.filter('dateFormat', (value: string, format?: string) =>
  formatDate(new Date(value), format === undefined ? 'PPPPpppp' : format)
);

export const dateFormat = (date: Date, format?: string) =>
  formatDate(date, format === undefined ? 'PPPPpppp' : format);

export const timeAgo = (date: Date) => formatDistanceToNow(date);
