import {Module} from 'vuex-class-modules';
import store from '@/store';
import {userGroupApi as api} from '@/api';
import {
  UserGroupAttributes as Attributes,
  UserGroupCreate as Create,
  UserGroupResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {parseISO} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.UserGroup,
    attributes: {
      id: 0,
      isAdmin: false,
      label: '',
      created: new Date(),
      modified: new Date(),
      userCreated: {id: 0, firstName: '', lastName: ''},
      userModified: {id: 0, firstName: '', lastName: ''},
    },
  };
};

@Module
class UserGroupModule extends CrudModuleAbstract<Resource, Attributes, Create> {
  public cast(item: Resource): Resource {
    if (!(item.attributes.created instanceof Date)) {
      item.attributes.created = parseISO(String(item.attributes.created));
    }
    if (!(item.attributes.modified instanceof Date)) {
      item.attributes.modified = parseISO(String(item.attributes.modified));
    }
    return item;
  }
}

export const userGroupModule = new UserGroupModule(
  {
    store,
    name: ResourceType.UserGroup,
  },
  () => api,
  defaultItem
);

export const test = async () => await api.list();
