import {HttpClient} from '@/HttpClient';
import {
  JsonApiFetchingParams,
  JsonApiResponse,
  ActionCreate,
} from '@/interfaces';

export class CrudApiAbstract<T, TT> extends HttpClient {
  public async get(id: string, options?: JsonApiFetchingParams): Promise<T> {
    const params = this.getParams(options);
    const response = await this.instance.get<JsonApiResponse<T>>(`/${id}`, {
      params: params,
    });
    return response.data;
  }

  public async list(options?: JsonApiFetchingParams): Promise<T[]> {
    const params = this.getParams(options);
    const response = await this.instance.get<JsonApiResponse<T[]>>(``, {
      params: params,
    });
    return response.data;
  }

  public async post(resource: TT): Promise<T> {
    const response = await this.instance.post<JsonApiResponse<T>>(``, {
      data: resource,
    });
    return response.data;
  }

  public async postMulti(resource: TT[]): Promise<T[]> {
    const response = await this.instance.post<JsonApiResponse<T[]>>(``, {
      data: resource,
    });
    return response.data;
  }

  public async postActions(resource: ActionCreate): Promise<T> {
    const response = await this.instance.post<JsonApiResponse<T>>(`/actions`, {
      data: resource,
    });
    return response.data;
  }

  public async put(id: string, resource: TT): Promise<T> {
    const response = await this.instance.put<JsonApiResponse<T>>(`/${id}`, {
      data: resource,
    });
    return response.data;
  }

  public async delete(id: string): Promise<T> {
    const response = await this.instance.delete<JsonApiResponse<T>>(`/${id}`);
    return response.data;
  }

  protected getParams(options?: JsonApiFetchingParams): Record<string, string> {
    if (options === undefined) {
      return {};
    }
    const filters = options.filters;
    const page = options.page;
    const search = options.search;
    const params: Record<string, string> = {};
    if (filters !== undefined) {
      Object.keys(filters).map((filter: string) => {
        params[`filter[${filter}]`] = filters[filter];
        return null;
      });
    }
    if (page && page.limit !== undefined) {
      params[`page[limit]`] = String(page.limit);
    }
    if (page && page.offset !== undefined) {
      params[`page[offset]`] = String(page.offset);
    }
    if (search) {
      params[`search`] = search;
    }
    return params;
  }
}
