import {SurveyRecipientCreate, SurveyRecipientResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class SurveyRecipientApi extends CrudApiAbstract<
  SurveyRecipientResource,
  SurveyRecipientCreate
> {
  public constructor() {
    super(`${ResourceType.SurveyRecipient}s`);
  }
}
