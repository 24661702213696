import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import {authModule} from '@/store/modules';
import {Route} from '@/enums';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/auth',
    name: Route.Home,
  },
  {
    path: '/auth',
    name: Route.Auth,
    meta: {auth: false, title: 'Sign In'},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
  },
  {
    path: '/dashboard',
    name: Route.Dashboard,
    meta: {auth: true, title: 'Home'},
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
  },
  {
    path: '/question-collections',
    name: Route.QuestionCollections,
    meta: {auth: true, title: 'Question Collections'},
    component: () =>
      import(
        /* webpackChunkName: "question-collections" */ '../views/QuestionCollections.vue'
      ),
  },
  {
    path: '/sent-emails',
    name: Route.SentEmails,
    meta: {auth: true, title: 'Sent Emails'},
    component: () =>
      import(/* webpackChunkName: "sent-emails" */ '../views/SentEmails.vue'),
  },
  {
    path: '/submissions/:id',
    name: Route.SubmissionsView,
    meta: {auth: true, title: 'Submissions'},
    component: () =>
      import(
        /* webpackChunkName: "submissions-view" */ '../views/Submission.vue'
      ),
  },
  {
    path: '/surveys/:id/recipients',
    name: Route.SurveyRecipients,
    meta: {auth: true, title: 'Recipients'},
    component: () =>
      import(/* webpackChunkName: "recipients" */ '../views/Recipients.vue'),
  },
  {
    path: '/surveys/:id/invitations',
    name: Route.SurveyInvitations,
    meta: {auth: true, title: 'Invitations'},
    component: () =>
      import(/* webpackChunkName: "invitations" */ '../views/Invitations.vue'),
  },
  {
    path: '/surveys/:id/submissions',
    name: Route.Submissions,
    meta: {auth: true, title: 'Submissions'},
    component: () =>
      import(/* webpackChunkName: "submissions" */ '../views/Submissions.vue'),
  },
  {
    path: '/survey-wizard',
    name: Route.SurveyWizard,
    meta: {auth: true, title: 'Create Form Wizard'},
    component: () =>
      import(
        /* webpackChunkName: "survey-wizard" */ '../views/SurveyWizard.vue'
      ),
  },
  {
    path: '/surveys',
    name: Route.Surveys,
    meta: {auth: true, title: 'Surveys'},
    component: () =>
      import(/* webpackChunkName: "surveys" */ '../views/Surveys.vue'),
  },
  {
    path: '/surveys/:id/edit',
    name: Route.SurveysEdit,
    meta: {auth: true, title: 'Edit Survey'},
    component: () =>
      import(/* webpackChunkName: "survey-edit" */ '../views/SurveyEdit.vue'),
  },
  {
    path: '/surveys/:id/embed',
    name: Route.SurveysEmbed,
    meta: {auth: true, title: 'Embed Survey'},
    component: () =>
      import(/* webpackChunkName: "survey-embed" */ '../views/SurveyEmbed.vue'),
  },
  {
    path: '/users',
    name: Route.Users,
    meta: {auth: true, title: 'Users'},
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue'),
  },
  {
    path: '/user-groups',
    name: Route.UserGroups,
    meta: {auth: true, title: 'User Groups'},
    component: () =>
      import(/* webpackChunkName: "user-groups" */ '../views/UserGroups.vue'),
  },
  {
    path: '*',
    name: Route.NotFound,
    meta: {requiresAuth: false},
    component: () =>
      import(/* webpackChunkName: 'not-found' */ '@/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title =
    `${to.meta.title} - Form Builder` || 'Communitech Form Builder';
  next();
});

router.beforeEach((to, from, next) => {
  const routeInfo = {
    to: to.name,
    from: from.name,
    toRouteRequiresAuth: to.matched.some(record => record.meta.auth),
    isUserAuthenticated: authModule.isAuthenticated,
  };
  console.debug('router.beforeEach', routeInfo);

  if (to.matched.some(record => record.meta.auth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!authModule.isAuthenticated) {
      // preserve the redirect parameter if set, if not set it
      const redirect =
        to.query.redirect === undefined
          ? encodeURIComponent(to.fullPath)
          : to.query.redirect;
      next({
        name: 'auth',
        query: {redirect: redirect},
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
