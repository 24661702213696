import {AuthApi} from '@/api/AuthApi';
import {DisplayControlApi} from '@/api/DisplayControlApi';
import {DropdownChainApi} from '@/api/DropdownChainApi';
import {QuestionApi} from '@/api/QuestionApi';
import {QuestionCollectionApi} from '@/api/QuestionCollectionApi';
import {QuestionItemApi} from '@/api/QuestionItemApi';
import {SubmissionApi} from '@/api/SubmissionApi';
import {SurveyApi} from '@/api/SurveyApi';
import {SurveyFieldApi} from '@/api/SurveyFieldApi';
import {SurveyFieldItemApi} from '@/api/SurveyFieldItemApi';
import {SurveyPageApi} from '@/api/SurveyPageApi';
import {UserApi} from '@/api/UserApi';
import {UserGroupApi} from '@/api/UserGroupApi';
import {AssetApi} from '@/api/AssetApi';
import {ProductApi} from '@/api/ProductApi';
import {SubmissionResponseApi} from '@/api/SubmissionResponseApi';
import {SubmitterMessageApi} from '@/api/SubmitterMessageApi';
import {SurveyRecipientApi} from '@/api/SurveyRecipientApi';
import {EmailApi} from '@/api/EmailApi';
import {IndexApi} from '@/api/IndexApi';
import {AttachmentApi} from '@/api/AttachmentApi';

export const assetApi = new AssetApi();
export const attachmentApi = new AttachmentApi();
export const authApi = new AuthApi();
export const emailApi = new EmailApi();
export const displayControlApi = new DisplayControlApi();
export const dropdownChainApi = new DropdownChainApi();
export const indexApi = new IndexApi();
export const productApi = new ProductApi();
export const questionApi = new QuestionApi();
export const questionCollectionApi = new QuestionCollectionApi();
export const questionItemApi = new QuestionItemApi();
export const submissionApi = new SubmissionApi();
export const submissionResponseApi = new SubmissionResponseApi();
export const submitterMessageApi = new SubmitterMessageApi();
export const surveyApi = new SurveyApi();
export const surveyFieldApi = new SurveyFieldApi();
export const surveyFieldItemApi = new SurveyFieldItemApi();
export const surveyPageApi = new SurveyPageApi();
export const surveyRecipientApi = new SurveyRecipientApi();
export const userApi = new UserApi();
export const userGroupApi = new UserGroupApi();
