import {
  JsonApiFetchingParams,
  SubmissionCreate,
  SubmissionResource,
} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class SubmissionApi extends CrudApiAbstract<
  SubmissionResource,
  SubmissionCreate
> {
  public constructor() {
    super(`${ResourceType.Submission}s`);
  }
  public async listTextCsv(options?: JsonApiFetchingParams): Promise<string> {
    const params = this.getParams(options);
    return await this.instance.get<string>(``, {
      params: params,
      headers: {Accept: 'text/csv'},
    });
  }
}
