import {Module} from 'vuex-class-modules';
import store from '@/store';
import {attachmentApi as api} from '@/api';
import {
  AttachmentAttributes as Attributes,
  AttachmentCreate as Create,
  AttachmentResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Attachment,
    attributes: {
      id: 0,
      contentType: '',
      size: '',
      name: '',
    },
  };
};

@Module
class AttachmentModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {}

export const attachmentModule = new AttachmentModule(
  {
    store,
    name: ResourceType.Attachment,
  },
  () => api,
  defaultItem
);
