import {
  SubmissionResponseCreate,
  SubmissionResponseResource,
} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class SubmissionResponseApi extends CrudApiAbstract<
  SubmissionResponseResource,
  SubmissionResponseCreate
> {
  public constructor() {
    super(`${ResourceType.SubmissionResponse}s`);
  }
}
