import {QuestionCreate, QuestionResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class QuestionApi extends CrudApiAbstract<
  QuestionResource,
  QuestionCreate
> {
  public constructor() {
    super(`${ResourceType.Question}s`);
  }
}
