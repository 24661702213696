import {Module} from 'vuex-class-modules';
import store from '@/store';
import {userApi as api} from '@/api';
import {
  UserAttributes as Attributes,
  UserCreate as Create,
  UserResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {parseISO} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.User,
    attributes: {
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      googleUserId: '',
      userGroups: [],
      userGroupIds: [],
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class UserModule extends CrudModuleAbstract<Resource, Attributes, Create> {
  public cast(item: Resource): Resource {
    item.attributes.created = parseISO(String(item.attributes.created));
    item.attributes.modified = parseISO(String(item.attributes.modified));
    return item;
  }
}

export const userModule = new UserModule(
  {
    store,
    name: ResourceType.User,
  },
  () => api,
  defaultItem
);
