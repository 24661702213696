import {SurveyFieldItemCreate, SurveyFieldItemResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class SurveyFieldItemApi extends CrudApiAbstract<
  SurveyFieldItemResource,
  SurveyFieldItemCreate
> {
  public constructor() {
    super(`${ResourceType.SurveyFieldItem}s`);
  }
}
