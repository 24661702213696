import {
  QuestionCollectionCreate,
  QuestionCollectionResource,
} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class QuestionCollectionApi extends CrudApiAbstract<
  QuestionCollectionResource,
  QuestionCollectionCreate
> {
  public constructor() {
    super(`${ResourceType.QuestionCollection}s`);
  }
}
