export enum Route {
  Auth = 'auth',
  Dashboard = 'dashboard',
  Home = 'home',
  NotFound = 'not-found',
  QuestionCollections = 'question-collections',
  SentEmails = 'sent-emails',
  SubmissionsView = 'submission-view',
  Submissions = 'submissions',
  SurveyWizard = 'survey-wizard',
  Surveys = 'surveys',
  SurveyInvitations = 'survey-invitations',
  SurveyRecipients = 'survey-recipients',
  SurveysEdit = 'surveys-edit',
  SurveysEmbed = 'surveys-embed',
  Users = 'users',
  UserGroups = 'user-groups',
}
