import {QuestionItemCreate, QuestionItemResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class QuestionItemApi extends CrudApiAbstract<
  QuestionItemResource,
  QuestionItemCreate
> {
  public constructor() {
    super(`${ResourceType.QuestionItem}s`);
  }
}
