import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import store from '@/store';
import {authApi} from '@/api';
import {AuthResource, GoogleAuth} from '@/interfaces';
import {sleep} from '@/utility';
import {ResourceType} from '@/enums';

@Module
class AuthModule extends VuexModule {
  // state
  auth: AuthResource | null = null;
  loading = false;
  gAuth: GoogleAuth | null = null;

  get isAuthenticated() {
    return this.auth === null ? false : this.auth.attributes.authenticated;
  }

  get isAdmin(): boolean {
    if (this.auth === null) {
      return false;
    }
    if (this.auth.attributes.userGroups.length === 0) {
      return false;
    }

    const adminGroups = this.auth.attributes.userGroups.filter(
      group => group.isAdmin
    );

    return Boolean(adminGroups.length);
  }

  get isAuthenticatedGoogle() {
    return this.gAuth === null ? false : this.gAuth.isAuthorized;
  }

  get isLoading() {
    return this.loading;
  }

  @Mutation
  setAuth(auth: AuthResource) {
    this.auth = auth;
  }

  @Mutation
  setGoogleAuth(gAuth: GoogleAuth) {
    this.gAuth = gAuth;
  }

  @Mutation
  setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  @Action
  async signOut(gAuth: GoogleAuth) {
    await gAuth.signOut();
    await authApi.delete('1');
    this.setAuth({
      id: '1',
      type: ResourceType.Auth,
      attributes: {authenticated: false, userId: null, userGroups: []},
    });
  }

  @Action
  async checkAuth(gAuth: GoogleAuth) {
    this.setLoading(true);

    // wait until GAuth is loaded
    const isGAuthLoaded = setInterval(async () => {
      if (gAuth.isInit) {
        // GAuth is loaded, clear timer
        clearInterval(isGAuthLoaded);
        if (process.env.NODE_ENV === 'development') {
          await sleep(1000);
        }

        this.setGoogleAuth(gAuth);
        console.info(
          'User is authenticated with Google:',
          this.isAuthenticatedGoogle
        );

        this.setAuth(await authApi.get('1'));

        // if user is NOT authenticated with google, but we are API authenticated, sign out
        if (!this.isAuthenticatedGoogle && this.isAuthenticated) {
          await authApi.delete('1');
        }

        // get the API auth status
        this.setAuth(await authApi.get('1'));
        console.info('User is authenticated with API:', this.isAuthenticated);

        if (this.isAuthenticatedGoogle && !this.isAuthenticated) {
          await gAuth.signOut();
          this.setGoogleAuth(gAuth);
        }

        // does user have API auth?
        this.setLoading(false);
      }
    }, 1000);
  }

  refreshAuth(gAuth: GoogleAuth) {
    // check auth every minute
    setInterval(async () => {
      console.log('Periodic auth check', gAuth.isAuthorized);
      if (!gAuth.isAuthorized) {
        await authApi.delete('1');
      }
      await authApi.get('1');
    }, 60000);
  }
}

// register module (could be in any file)

export const authModule = new AuthModule({store, name: 'auth'});
