import {VersionCreate, VersionResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';

export class IndexApi extends CrudApiAbstract<VersionResource, VersionCreate> {
  public constructor() {
    super(``);
  }

  public async getVersion(): Promise<string> {
    const response = await this.instance.get('');
    return response.data.attributes.version;
  }
}
