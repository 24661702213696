import {
  Action,
  Mutation,
  RegisterOptions,
  VuexModule,
} from 'vuex-class-modules';
import {
  ActionPayload,
  DataAttributes,
  DataResource,
  JsonApiFetchingParams,
} from '@/interfaces';
import {sleep} from '@/utility';
import Vue from 'vue';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export abstract class CrudModuleAbstract<
  T extends DataResource<TT>,
  TT extends DataAttributes,
  TTT
> extends VuexModule {
  protected allIds: string[] = [];
  protected api: () => CrudApiAbstract<T, TTT>;
  protected byId: Record<string, T> = {};
  protected defaultItem: () => T;
  protected isDeletingIds: string[] = [];
  protected isGettingIds: string[] = [];
  protected isDeleting = false;
  protected isGetting = false;
  protected isListing = false;
  protected isSaving = false;
  protected isSavingIds: string[] = [];

  constructor(
    options: RegisterOptions,
    api: () => CrudApiAbstract<T, TTT>,
    defaultItem: () => T
  ) {
    super(options);
    this.api = api;
    this.defaultItem = defaultItem;
  }

  /**
   * Returns true if any server action is being performed
   */
  get busy(): boolean {
    return this.reading || this.writing;
  }

  /**
   * Returns true if deleting API call is in progress
   */
  get deleting(): boolean {
    return this.isDeleting;
  }

  get deletingIds(): string[] {
    return this.isDeletingIds;
  }

  get find() {
    return (id: string): T => {
      return this.byId[String(id)];
    };
  }

  /**
   * Returns true if a single record is being fetched
   */
  get getting(): boolean {
    return this.isGetting;
  }

  get gettingIds(): string[] {
    return this.isGettingIds;
  }

  get list(): T[] {
    return this.allIds.map(id => this.find(id));
  }

  /**
   * If a multiple records are being retrieved from API
   */
  get listing(): boolean {
    return this.isListing;
  }

  /**
   * Alias for reading getter.
   */
  get loading(): boolean {
    return this.reading;
  }

  /**
   * If a API read action is being performed
   */
  get reading(): boolean {
    return this.listing || this.getting;
  }

  /**
   * If a API save action (PUT, POST, PATCH) is being performed
   */
  get saving(): boolean {
    return this.isSaving;
  }

  get savingIds(): string[] {
    return this.isSavingIds;
  }

  /**
   * If a write API action is being performed
   */
  get writing(): boolean {
    return this.saving || this.deleting;
  }

  @Action
  public async loadMulti(options?: JsonApiFetchingParams) {
    if (options && options.append === undefined) {
      options.append = false;
    }
    this.setListing(true);
    if (process.env.NODE_ENV === 'development') {
      await sleep();
    }
    try {
      const items = await this.api().list(options);
      if (!options || (options && options.append === false)) {
        this.clear();
      }
      items.forEach(item => {
        //console.log('add', item);
        this.add(item);
      });
    } catch (e) {
      console.error('CrudModuleAbstract.loadMulti', e);
    }
    this.setListing(false);
    return this.list;
  }

  @Action
  public async action(payload: ActionPayload): Promise<DataResource<TT>> {
    this.setSaving(true);
    try {
      if (process.env.NODE_ENV === 'development') {
        await sleep();
      }
      return await this.api().postActions({
        type: ResourceType.Action,
        attributes: payload.attributes,
      });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      this.setSaving(false);
    }
  }

  @Action
  public async loadById(id: string): Promise<T> {
    id = String(id);
    if (id.substr(0, 3) === 'new') {
      const item = this.defaultItem();
      item.id = id;
      this.add(item);
      return item;
    }
    this.setGetting(true);
    this.addGettingId(id);
    try {
      if (process.env.NODE_ENV === 'development') {
        await sleep();
      }
      const item = await this.api().get(id);
      this.add(item);
      this.setGetting(false);
      this.removeGettingId(id);
      return this.find(item.id);
    } catch (err) {
      console.error('loadById', err);
      this.setGetting(false);
      this.removeGettingId(id);
      throw Error(`Could not get item by ID: ${id}`);
    }
  }

  @Action
  public reorder(resources: T[]) {
    let newIndex = 0;
    resources.forEach(item => {
      item.attributes.order = newIndex;
      this.add(item);
      newIndex++;
    });
  }

  @Action
  public async save(id: string) {
    id = String(id);
    const isNew = id.substr(0, 3) === 'new';
    let item: T = this.find(id);
    // Cast a xxxResource object to a xxxCreate object
    const createItem: TTT = (item as unknown) as TTT;

    this.setSaving(true);
    this.addSavingId(id);
    try {
      if (process.env.NODE_ENV === 'development') {
        await sleep();
      }
      if (isNew) {
        item = await this.api().post(createItem);
        this.remove(id);
      } else {
        item = await this.api().put(id, createItem);
      }

      // regardless of the item being new or old, add it again to state
      this.add(item);
      this.setSaving(false);
      this.removeSavingId(id);
    } catch (err) {
      this.setSaving(false);
      this.removeSavingId(id);
      throw err;
    }

    return this.find(item.id);
  }

  @Action
  public async delete(id: string) {
    this.setDeleting(true);
    this.addDeletingId(id);
    try {
      if (process.env.NODE_ENV === 'development') {
        await sleep();
      }
      await this.api().delete(id);
      this.setDeleting(false);
      this.removeDeletingId(id);
      this.remove(id);
    } catch (err) {
      this.setDeleting(false);
      this.removeDeletingId(id);
      console.error(err);
      throw err;
    }

    return true;
  }

  @Action
  public deleteNoPersist(id: string): boolean {
    this.remove(id);
    return true;
  }

  @Action
  public reset(): void {
    this.clear();
  }

  protected cast(item: T): T {
    return item;
  }

  @Mutation
  protected add(item: T) {
    const itemId = String(item.id);

    Vue.set(this.byId, itemId, this.cast(item));
    // if we already have this id saved don't add the id
    if (!this.allIds.includes(itemId)) {
      this.allIds.push(itemId);
    }
  }

  @Mutation
  protected clear() {
    this.allIds = [];
    this.byId = {};
  }

  @Mutation
  protected addDeletingId(id: string): void {
    id = String(id);
    if (!this.isDeletingIds.includes(id)) {
      this.isDeletingIds.push(id);
    }
  }

  @Mutation
  protected removeDeletingId(id: string): void {
    this.isDeletingIds.splice(this.isDeletingIds.indexOf(String(id)), 1);
  }

  @Mutation
  protected setGetting(status: boolean) {
    this.isGetting = status;
  }

  @Mutation
  protected addGettingId(id: string): void {
    id = String(id);
    if (!this.isGettingIds.includes(id)) {
      this.isGettingIds.push(id);
    }
  }

  @Mutation
  protected removeGettingId(id: string): void {
    this.isGettingIds.splice(this.isGettingIds.indexOf(String(id)), 1);
  }

  @Mutation
  protected setListing(status: boolean) {
    this.isListing = status;
  }

  @Mutation
  protected setSaving(status: boolean) {
    this.isSaving = status;
  }

  @Mutation
  protected addSavingId(id: string): void {
    id = String(id);
    if (!this.isSavingIds.includes(id)) {
      this.isSavingIds.push(id);
    }
  }

  @Mutation
  protected removeSavingId(id: string): void {
    this.isSavingIds.splice(this.isSavingIds.indexOf(String(id)), 1);
  }

  @Mutation
  protected setDeleting(status: boolean) {
    this.isDeleting = status;
  }

  @Mutation
  protected remove(id: string) {
    this.allIds.splice(this.allIds.indexOf(String(id)), 1);
    Vue.delete(this.byId, String(id));
  }
}
