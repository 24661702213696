import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './filters';
import vuetify from './plugins/vuetify';
import GAuth from 'vue-google-oauth2';
import Clipboard from 'v-clipboard';

Vue.config.productionTip = false;

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account',
};

Vue.use(GAuth, gauthOption);
Vue.use(Clipboard);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
