import {InputValidationRules} from 'vuetify';

export const rulesRequired: InputValidationRules = [
  v => !!v || 'This field is required',
];

export const rulesEmail: InputValidationRules = [
  v => !!v || 'This field is required',
  v => /.+@.+/.test(v) || 'This field must be valid',
];

const validateEmails = (v: string, required: boolean): boolean => {
  if (!required && !v) {
    return true;
  }
  const emails = v.split(',');
  for (const email of emails) {
    if (!/.+@.+/.test(email.trim())) {
      return false;
    }
  }
  return true;
};

export const rulesMultipleEmailsNotRequired: InputValidationRules = [
  v => validateEmails(v, false) || 'This field must be valid',
];

export const rulesMultipleEmailsRequired: InputValidationRules = [
  v => validateEmails(v, true) || 'This field must be valid',
];
