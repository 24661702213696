import {UserGroupCreate, UserGroupResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class UserGroupApi extends CrudApiAbstract<
  UserGroupResource,
  UserGroupCreate
> {
  public constructor() {
    super(`${ResourceType.UserGroup}s`);
  }
}
