import {Module} from 'vuex-class-modules';
import store from '@/store';
import {surveyRecipientApi as api} from '@/api';
import {
  SurveyRecipientAttributes as Attributes,
  SurveyRecipientCreate as Create,
  SurveyRecipientResource as Resource,
} from '@/interfaces';
import {RecipientStatus, ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {parseISO} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.SurveyRecipient,
    attributes: {
      id: 0,
      communitechCompanyId: '',
      communitechCompany: '',
      surveyId: 0,
      firstName: '',
      lastName: '',
      email: '',
      etlRun: false,
      messageId: '',
      status: RecipientStatus.Pending,
      userCreated: {id: 0, firstName: '', lastName: ''},
      userModified: {id: 0, firstName: '', lastName: ''},
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class SurveyRecipientModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {
  public cast(item: Resource): Resource {
    item.attributes.created = parseISO(`${String(item.attributes.created)}`);
    item.attributes.modified = parseISO(`${String(item.attributes.modified)}`);
    return item;
  }
}

export const surveyRecipientModule = new SurveyRecipientModule(
  {
    store,
    name: ResourceType.SurveyRecipient,
  },
  () => api,
  defaultItem
);
