import {SurveyPageCreate, SurveyPageResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class SurveyPageApi extends CrudApiAbstract<
  SurveyPageResource,
  SurveyPageCreate
> {
  public constructor() {
    super(`${ResourceType.SurveyPage}s`);
  }
}
