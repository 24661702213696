import {SelectItem} from '@/interfaces';

export const recipientCsvHeaders: SelectItem[] = [
  {text: 'Valid', value: 'valid'},
  {text: 'mFormsID', value: 'mFormsId'},
  {text: 'Company Name', value: 'companyName'},
  {text: 'First Name', value: 'firstName'},
  {text: 'Last Name', value: 'lastName'},
  {text: 'Email', value: 'email'},
  {text: 'Account Manager Email', value: 'accountManagerEmail'},
  {text: 'Account Manager Name', value: 'accountManagerName'},
  {text: 'Actions', value: 'actions'},
];
