import {AssetCreate, AssetResource, JsonApiResponse} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class AssetApi extends CrudApiAbstract<AssetResource, AssetCreate> {
  public constructor() {
    super(`${ResourceType.Asset}s`);
  }

  public async postFile(file: File): Promise<AssetResource> {
    const data = new FormData();
    data.append('file', file, file.name);
    const response = await this.instance.post<JsonApiResponse<AssetResource>>(
      ``,
      data,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      }
    );

    return response.data;
  }
}
