import {DropdownChainCreate, DropdownChainResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class DropdownChainApi extends CrudApiAbstract<
  DropdownChainResource,
  DropdownChainCreate
> {
  public constructor() {
    super(`${ResourceType.DropdownChain}s`);
  }
}
