export enum ResourceType {
  Action = 'action',
  Attachment = 'attachment',
  Auth = 'auth',
  Asset = 'asset',
  DisplayControl = 'display-control',
  DropdownChain = 'dropdown-chain',
  Email = 'email',
  Version = 'version',
  Question = 'question',
  QuestionCollection = 'question-collection',
  QuestionItem = 'question-item',
  Product = 'product',
  Submission = 'submission',
  SubmissionResponse = 'submission-response',
  SubmitterMessage = 'message',
  Survey = 'survey',
  SurveyField = 'survey-field',
  SurveyFieldItem = 'survey-field-item',
  SurveyPage = 'survey-page',
  SurveyRecipient = 'survey-recipient',
  User = 'user',
  UserGroup = 'user-group',
}
