import {Module} from 'vuex-class-modules';
import store from '@/store';
import {productApi as api} from '@/api';
import {
  ProductAttributes as Attributes,
  ProductCreate as Create,
  ProductResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {parseISO} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Product,
    attributes: {
      id: 0,
      dwProductId: 0,
      sfProductId: 0,
      formAlias: '',
      programName: '',
      productName: '',
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class ProductModule extends CrudModuleAbstract<Resource, Attributes, Create> {
  public cast(item: Resource): Resource {
    item.attributes.created = parseISO(String(item.attributes.created));
    item.attributes.modified = parseISO(String(item.attributes.modified));
    return item;
  }
}

export const productModule = new ProductModule(
  {
    store,
    name: ResourceType.Product,
  },
  () => api,
  defaultItem
);
