import {Module} from 'vuex-class-modules';
import store from '@/store';
import {submissionApi as api} from '@/api';
import {
  SubmissionAttributes as Attributes,
  SubmissionCreate as Create,
  SubmissionResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {parseISO} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Submission,
    attributes: {
      id: 0,
      bucket: '',
      communitechCompanyId: '',
      dataSample: '',
      ipAddress: '',
      requestUrl: '',
      userAgent: '',
      surveyId: 0,
      surveyRecipientId: null,
      surveyRecipient: null,
      etlProcessed: false,
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class SubmissionModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {
  public cast(item: Resource): Resource {
    item.attributes.created = parseISO(`${String(item.attributes.created)}`);
    item.attributes.modified = parseISO(`${String(item.attributes.modified)}`);
    return item;
  }
}

export const submissionModule = new SubmissionModule(
  {
    store,
    name: ResourceType.Submission,
  },
  () => api,
  defaultItem
);
